.v2-navbar-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.v2-user-login-btn {
    background: #C10046 !important;
    border-radius: 70px;
    text-transform: none !important;
    padding: 7px 15px;
    border-color: #C10046 !important;
}
.v2-user-login-btn-label {
    color: #FFFFFF;
    padding: 1px 10px;
    font-size: 15px;
    font-weight: 600;
}
section#v2-carousel-section {
    margin-top: -3%;
    position: relative;
}

.carousel-item-container {
    position: relative;
}

.carousel-item-legend-header {
    color: #FFFFFF;
    width: 100%;
    padding: 15px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
}
.carousel-item-legend-slogan {
    color: #FFFFFF;
    width: 100%;
    padding: 15px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 21px;
}
.carousel-item-image {
    z-index: 1;
}
section#v2-auto-eval-btn-section {
    position: absolute;
    bottom: -4%;
    width: 100%;
}
.v2-auto-eval-btn-container {
    display: flex;
    justify-content: center;
}
.v2-auto-eval-btn {
    z-index: 99;
    background: linear-gradient(89.78deg, #FF8282 0.19%, #C10047 101.38%) !important;
    box-shadow: 6px 19px 9px -9px rgba(0, 0, 0, 0.25);
    border-radius: 140px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF !important;
    padding: 10px;
    border-color: transparent !important;
}
.v2-auto-eval-btn-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #FFFFFF;
    padding: 10px;
    border-radius: 90px;
}
.v2-auto-eval-btn-label {
    padding: 2px 35px;
}
.v2-auto-eval-icon-label {
    width: 50%;
}

/* Services */
#v2-services-section {
    margin-top: 5%;
    background: #FFF7F7;
    padding: 60px 150px;
}
.v2-services-body-listing-items {
    display: flex;
    justify-content: space-between;
}
.v2-service-item-container {
    width: 25%;
    padding: 20px;
    margin: 5px;
    margin-bottom: 20px;
    position: relative;
}
.v2-service-icon-container {
    text-align: center;
    margin-bottom: 20px;
}
.v2-service-icon {
    background: #C10046;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.v2-service-name {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #273375;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.v2-service-card-header {
    margin-bottom: 20px;
}
.v2-explorer-btn {
    width: 100%;
    background: #273375 !important;
    border-radius: 70px;
    color: #FFFFFF !important;
    text-transform: capitalize;
    font-size: 17px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
}
.v2-service-item-container.v2-orientation-services-container:after,
.v2-service-item-container.v2-informations-services-container:after,
.v2-service-item-container.v2-school-or-university-services-container:after {
    content: "";
    width: 1px;
    height: 200px;
    background: #C10046;
    position: absolute;
    top: 0;
    right: -4%;
}

/* News Feed */
.custom-section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 58px;
    color: #273375;
    margin: 25px 0;
}
.news-feed-body-content,
.events-carousel-body-content {
    position: relative;
}
.custom-section-title:before {
    content: "";
    position: absolute;
    width: 70px;
    height: 5px;
    background: #C10046 !important;
    left: 1%;
}
.news-feed-item-image {
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}
.bews-feed-slide-item-card {
    padding: 20px;
}
.news-feed-item-title-container {
    background: linear-gradient(180.1deg, #FF8282 57.11%, #C10046 99.91%);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    text-align: center;
    padding: 20px;
}
.news-title {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slick-arrow.slick-prev {
    background: #273375;
    padding: 17px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}
.slick-prev:before {
    font-size: 21px;
    line-height: 0 !important;
}
.slick-arrow.slick-next {
    background: #273375;
    padding: 17px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

/* Events */
.container.v2-events-carousel-container {
    padding: 100px 0;
}
.v2-events-carousel-container .carousel-indicators {
    left: -86%;
}
.carousel .carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
}
.carousel-indicators .active {
    background-color: #273375 !important;
    width: 15px;
    height: 15px;
}
.v2-events-carousel-container .carousel-control-prev {
    bottom: -70%;
    left: 2%;
    opacity: 1;
}
.v2-events-carousel-container .carousel-control-prev-icon {
    background-color: #2C3674;
    background-size: 35px;
    opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.v2-events-carousel-container .carousel-control-next {
    bottom: -70%;
    left: 10%;
    opacity: 1;
}
.v2-events-carousel-container .carousel-control-next-icon {
    background-color: #2C3674;
    background-size: 35px;
    opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.carousel-item-textual {
    position: absolute;
    top: 50%;
    left: 9%;
    background: #00000010;
    padding: 10px;
    z-index: 99999999999;
}
.carousel-item-textual p {
    font-size: 25px;
    color: #FFFFFF;
    font-weight: 600;
    text-align: center;
}
.carousel-textual-link:hover {
    color: #C10046 !important;
}

/* Footer */
#v2-footer {
    background: #273375 !important;
    padding: 0 !important;
}
.v2-footer-content-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.v2-footer-org-infos-container {
    padding: 0 40px;
}
.v2-footer-container.container {
    padding: 25px 0;
}
.footer-org-newsletter-container p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 25px 0;
}
.v2-footer-newsletter-form-control {
    background-image: none;
    padding: 25px;
    border-radius: 50px;
}
.v2-footer-form-group-container {
    position: relative;
}
.v2-footer-newsletter-submit-btn-container {
    position: absolute;
    top: -3%;
    right: 0%;
}
.v2-footer-newsletter-submit-btn {
    background: #C10046 !important;
    border-radius: 50px;
    padding: 11px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
}
.v2-footer-copyrights-container {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 20px 40px;
}
.v2-footer-contact-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
}
.v2-footer-contact-label {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}
.v2-footer-contacts-container {
    margin-bottom: 25px;
}
.v2-social-medias-card {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.v2-footer-social-medias-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}
.v2-footer-org-contact-container {
    padding: 50px 40px;
}

/* Modal */
.v2-modal-header-content {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
}
.ReactModal__Content.ReactModal__Content--after-open {
    border-radius: 20px !important;
}
.v2-return-back-btn {
    background: #273375 !important;
    border-radius: 70px;
    padding: 7px;
    border-color: #273375 !important;
    font-weight: 600;
}
.return-back-label {
    color: #FFFFFF !important;
    padding: 10px;
}
.return-back-icon {
    background: #C10046;
    padding: 7px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.modal-header-title-group {
    display: flex;
    align-items: center;
}
.modal-header-title-icon {
    background: #C10046;
    padding: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.modal-header-title-label {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #273375;
    padding: 0 15px;
}
.v2-modal-body-content-container {
    padding: 0 30px;
    margin: 70px 0;
}
.v2-modal-body-item-btn {
    box-shadow: 10px 9px 29px -2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px 70px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF !important;
    text-transform: none;
}
section#v2-carousel-section {
    z-index: 0;
}
.sub-service-item-label {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.v2-modal-body-item-btn-on-full-width {
    width: 100%;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #fff!important;
    text-transform: none;
}
.sub-service-item-label:first-letter {
    text-transform: uppercase;
}
.v2-modal-empty-badge-body {
    background: #C10046;
    box-shadow: 10px 9px 29px -2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 150px;
    /* padding: 45px; */
}
.v2-modal-empty-badge-icon-container {
    text-align: center;
    margin-bottom: 30px;
}
.v2-modal-empty-badge-message-container {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}
.ReactModal__Overlay {
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    border-radius: 20px !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
    border-radius: 20px !important;
    background: #FFF;
}
.v2-modal-content-container {
    padding: 20px 0;
    background: #FFF6F6;
    box-shadow: 0px 8px 25px rgb(0 0 0 / 10%);
    border-radius: 20px;
}
.v2-modal-body-item-btn {
    width: 100%;
}
.news-feed-item-image {
    height: 250px;
    width: 100%;
}



.search-bar-content{
    background: rgba(255, 255, 255, 0.3);
    padding: 7px;
    border-radius: 25px;
}
.titre-search-bar{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}
.btn-submit-search-bar{
    width: 20%;
    color: #fff;
    background: #E61C18;
    height: 50px;
    border: 1px solid transparent;
}
.input-submit-search-bar{
  width: 100%;
  /* height: 50px; */
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid transparent;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 22px;
  color: #111111;
  padding: 15px;
  font-weight: 600;
}
form.homepage-search-form{
    margin-bottom: 0 !important;
}
select {

    /* styling */
    background-color: white;
    border: thin solid #fff;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 9px;
  
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  
  /* arrows */
  
  select.classic {
    background-image:
      linear-gradient(45deg, transparent 50%, #fff 50%),
      linear-gradient(135deg, #fff 50%, transparent 50%),
      linear-gradient(to right, #E61C18, #E61C18);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }

.so-calendar-item-container {
    padding: 20px;
    border: 1px solid #273375 !important;
    background: #fff;
    margin-bottom: 8px;
    border-radius: 10px;
    display: flex !important;
    align-items: center;
}
.v_event_date {
  font-size: 15px;
  font-weight: 900;
  padding: 20px;
  background: #ececec;
  color: #273375 !important;
  text-align: left;
}
.event-date-item {
    padding: 10px 0;
    text-align: center;
    color: #273375 !important;
}
.checkout-btn {
    width: 100%;
    height: auto !important;
    color: #fff !important;
    background: #273375 !important;
    padding: 15px;
    border-color: #273375 !important;
    margin: 0;
}
.checkout-icon {
    width: 50px;
    height: 50px;
}
.col-vertical-align-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v_event_infos {
  font-size: 20px;
  line-height: 31px;
  text-align: center;
}
.v_event_date_horaire {
  color: #273375 !important;
  font-weight: 600;
}
.v_event_type {
  font-weight: 900;
}
.places_counter {
  color: rgb(230, 65, 75);
  font-weight: 600;
}
.checkout-btn.background-opacity {
  opacity: .3 !important;
  cursor: not-allowed !important;
}
.visiteur_youtube_preview {
  width: 100% !important;
}
.tuto-video-item-col {
  margin-bottom: 20px;
}
.play-tuto-btn {
  width: 100%;
  background: #273375 !important;
  padding: 3px;
  color: #fff !important;
  border-radius: 50px;
  margin: 5px 0;
  border: 1px solid #273375 !important;
}
.play-btn-container {
  width: 100% !important;
}
.youtube-play-icon {
  width: 50px;
  height: 50px;
}
.play-tuto-btn:hover {
  background: #fff !important;
  color: #273375 !important;
}
.so-row{
  margin: 20px 0;
}
.visiteur_youtube_video_playing {
  width: 100% !important;
  margin-bottom: 90px;
}
.empty-alert-row {
  width: 100%;
}
.event-item-fcontainer {
  margin-bottom: 25px;
  border-radius: 10px;
}
.checkout_event_ville {
    border: 1px solid #273375 !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: center;
    background: #273375 !important;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: -10px;
}
.event_ville_for_checkout {
  text-align: center;
  color: #FFF;
  padding: 10px;
  font-size: 30px;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #273375 !important;
  border-radius: 10px;
  margin-bottom: -2%;
  background: #273375 !important;
}
.checkout-event-modal .modal-body {
  padding-top: 0;
}
.checkout-event-modal form {
  border: 1px solid #273375 !important;
  border-radius: 10px;
}
.visiteur_youtube_video_playing {
    height: 640px !important;
}
.carousel-item {
    position: relative;
}
.carousel-item-btn-container {
    position: absolute;
    bottom: 30%;
    left: 16%;
}
.event-subscribe-btn {
    background: #273375 !important;
    border-color: #273375 !important;
    padding: 10px 30px;
    color: #FFF !important;
    font-weight: 600;
}
.carousel-control-prev, .carousel-control-next{
    width: 32px !important;
}
.checking-meeting-row {
    align-items: center;
}
.no-empty-rdv-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    color: #C10046;
    padding: 20px 0;
}
.no-empty-rdv-ul {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #C10046;
}
.no-empty-rdv-ul li {
    padding: 7px 0;
}
.react-calendar__tile--active {
    background: #C10046 !important;
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
}
.empty-rdv-textual-container {
    font-family: 'Campus France Sans Italique Beta AA';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #C10046;
}
.event-location-label, 
.event-particant-count {
    color: #273375 !important;
}
.disable-item {
    opacity: .6;
}
.slide-item-action-btn {
    /* position: absolute;
    bottom: 17%;
    left: 6%;
    background: #C10046 !important;
    color: #FFFFFF !important;
    padding: 15px 80px;
    border-radius: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center; */
    background: #C10046 !important;
    color: #FFFFFF !important;
    padding: 15px 80px;
    border-radius: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    margin-top: 0% !important;
    display: inline-block;
}
.ae-home-deco-btn-img-container:before {
    background: #CFD1DA50 !important;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
}
.ae-home-deco-btn-img-container {
    position: relative;
}
.ae-btn-deco-container {
    margin: auto;
    text-align: center;
}
.ae-home-btn-deco-container {
    position: relative;
}
.ae-home-btn-textual-container {
    position: absolute;
    top: 34%;
    left: 0;
    right: 0;
    margin: auto;
}
.ae-home-intro-textual {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.ae-home-intro-textual span {
    display: block;
    margin-bottom: 15px;
}
.ae-home-intro-textual-slogan {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}
.ae-home-new-btn {
    background: #273375;
    border-radius: 70px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 55px;
    margin: 20px !important;
    display: inline-block;
}
.ae-home-new-btn:hover {
    background: #ffffff !important;
    color: #273375 !important;
    border: 1px solid #273375 !important;
}

.mesp-carousel-item-legend-header {
    color: #FFF;
    font-size: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.mesp-carousel-item-legend-slogan {
    color: #FFF;
    font-size: 25px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.mesp-slide-item-action-btn {
    border-radius: 140px;
    background: linear-gradient(90deg, #FF8282 0%, #C10047 100%);
    box-shadow: 6px 19px 9px -9px rgba(0, 0, 0, 0.25);
    padding: 15px 60px;
    display: inline-block;
    color: #FFF;
    font-size: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 5%;
}
.mesp-homepage-carousel-item-row {
    align-items: center;
    padding: 5% 3%;
}
.mesp-ae-btn-deco-container {
    position: relative;
}
.mesp-ae-home-btn-textual-container {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    margin: auto;
}
.mesp-ae-home-intro-textual {
    color: #FFF;
    text-align: center;
    font-size: 25px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin: 15px 0 !important;
}
.mesp-ae-home-intro-textual-slogan {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin: 15px 0 !important;
}
.mesp-ae-home-new-btn {
    border-radius: 140px;
    background: linear-gradient(90deg, #FF8282 0%, #C10047 100%);
    box-shadow: 6px 19px 9px -9px rgba(0, 0, 0, 0.25);
    padding: 10px 40px;
    display: inline-block;
    border: 1px solid #ffffff;
    color: #FFF;
    font-size: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 15px;
}
.mesp-ae-home-new-btn:hover {
    background: #ffffff !important;
    color: #C10047 !important;
}
.mesp-slide-item-action-btn:hover {
    background: #ffffff !important;
    color: #C10047 !important;
}
