.component-configurations nav.navbar.navbar-light.bg-white.box-shadow {
    margin-bottom: 0 !important;
}
.component-configurations .bordure-bas {
    border-bottom: 0 solid transparent !important;
}
.component-configurations section#cd_container {
    background: #273375 !important;
}
.component-configurations th {
    height: 50px;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #000 !important;
}
.component-configurations tr td {
    padding: 20px;
    font-size: 15px;
}
.oe-actions-btn-container button {
    color: #fff !important;
    border-radius: 9px;
}
.showing-item-label {
    font-weight: 600;
    border-bottom: 1px solid #ececec;
    padding-bottom: 8px;
}
.showing-item-col {
    border-bottom: 1px solid #ececec;
    margin: 15px 0px;
    padding-bottom: 15px;
    line-height: 23px;
    text-align: justify;
}