
.component-conseiller-chatroom .bordure-bas{
    border-bottom-color: transparent !important;
}
.conseiller-outils-etudiant-chatroom-component {
    width: 100%;
    background: linear-gradient(90deg, rgba(0,96,172,0.8074580173866421) 0%, rgba(0,96,172,0.40689779329700626) 100%);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 50px;
}
.component-admin-chatroom .s-h-primo-1 {
    background: #273375 !important;
    width: 100%;
    padding: 0%;
}
.component-admin-chatroom .bordure-bas {
    border-bottom-color: transparent !important;
}