#generated_event_form {
    background: #fff !important;
    padding: 50px 15px;
    border-radius: 25px;
}
#generated_event_form label {
    color: #273375 !important;
    font-size: 17px !important;
    font-weight: 600;
    // padding-left: 10px;
}
.component-generated-event-link-form .row.empty-alert-row {
    margin: 0;
}
.component-generated-event-link-form .half-top-accueil {
    margin-top: 8% !important;
}
.generated-link-formulaire-title {
    margin-top: 20px !important;
    padding: 40px 0;
    color: #273375 !important;
    text-transform: uppercase;
    font-family: 'Poppins';
}