/* .logo-header{
    width: 390px;
} */

.btn-access-compte{
    background-color: #31B89B;
    border: 1px solid #31B89B;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.205em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 15px;
    transition: .2s ease-in-out;
    border-radius: 50px;
}
.btn-access-compte:hover{
    border: 1px solid #31B89B;
    transition: .2s ease-in-out;
    text-decoration: none;
    background-color: #ffffff;
    color: #31B89B;

}
h1.admin-dashboard-title {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    color: #273375;
    background: #F1F9FF;
    padding: 20px;
    border-radius: 17px;
}
/* .account-access-link-container {
    margin-top: 15px;
} */
.btn-primary.oe-live-btn {
    background: #328BF8  !important;
    border: 1px solid #328BF8 !important;
    color: #fff !important;
    /* font-size: 23px; */
    font-weight: 600;
    border-radius: 25px;
    padding: 7px 30px !important;
}
.btn-primary.oe-live-btn:hover{
    background: #FFF !important;
    color: #328BF8 !important;
}
img.zoom-icon {
    width: 24px !important;
}
.btn-danger.oe-live-btn {
    background: #6c757d !important;
    border: 1px solid #6c757d !important;
    color: #fff !important;
    /* font-size: 23px; */
    font-weight: 600;
    border-radius: 25px;
    padding: 7px 30px !important;
}
.btn-danger.oe-live-btn:hover{
    background: #FFF !important;
    color: #6c757d !important;
}
.btn-warning.oe-live-btn {
    background: #FF0000 !important;
    border: 1px solid #FF0000 !important;
    color: #fff !important;
    /* font-size: 23px; */
    font-weight: 600;
    border-radius: 25px;
    padding: 7px 30px !important;
}
.btn-warning.oe-live-btn:hover{
    background: #FFF !important;
    color: #FF0000 !important;
}
.live-label {
    font-size: 15px !important;
}