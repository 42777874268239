.component-need-help-infos .s-h-primo-1 {
    background: #273375 !important;
    width: 100%;
    padding: 0%;
    margin-top: -3%;
}
.need-help-count {
    margin: 25px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.need-help-count-label {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}
.need-help-count-badge {
    background-color: #fff !important;
    font-size: 30px;
    border-radius: 25px;
    color: #273375 !important;
}
.visitor-needs-container p {
    color: #000;
    text-align: center;
}
// .export-btn-col {
//     text-align: right;
//     right: 7%;
//     bottom: -7px;
// }