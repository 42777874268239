.profil-etudiant-section-row {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.profil-etudiant-section-row .ae-quizz-item-content-body h2 {
    font-size: 20px;
    margin-bottom: 25px;
}
.infos-etudiant-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #C10046 !important;
}
.profil-etudiant-infos-col {
    margin-bottom: 20px;
}
.infos-etudiant-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #000000;
}
.request-error-section {
    margin-bottom: 50px;
}
.moyenne-etudiant-row {
    padding: 8px 0;
    border: 1px solid #ececec;
}
.print-btn-container {
    display: flex;
    justify-content: flex-end;
}
.export-profile-pdf-btn {
    background: #C10046 !important;
    border-radius: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff !important;
}