.header-navbar {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
#auth-form-section {
    padding-bottom: 50px;
}
.auth-form-body {
    margin: 100px 300px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 40px;
    border-radius: 10px;
}
.auth-form-title {
    padding: 20px 15px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    color: #273375 !important;
    font-size: 30px;
}
.auth-form-control {
    background-image: none !important;
    padding: 25px 10px;
    border-radius: 10px;
    border: 1px solid #273375!important;
}
.auth-submit-btn {
    width: 100%;
    background: #273375 !important;
    border-color: #273375 !important;
    padding: 10px;
    margin: 1px;
    color: #FFF !important;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px;
}
.auth-submit-btn-col {
    margin: 10px 0;
}
.auth-input-col {
    margin-bottom: 25px;
}
.auth-forget-password-link {
    width: 100%;
    background: #FFFFFF !important;
    padding: 10px;
    margin: 1px;
    color: #273375 !important;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px;
}