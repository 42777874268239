#showConseiller .modal-header {
    background: #273375 !important;
}

#showConseiller h5 {
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 15px;
}
#showConseiller button.close-btn {
    color: #fff;
    font-weight: 600;
}
#showConseiller button.close-btn{
    background: red !important;
    border-radius: 0;
    padding: 15px 30px;
    color: #fff !important;
    border: 1px solid red !important;
    border-radius: 50px;
}
#showConseiller .modal-footer{
    padding: 20px 24px 24px;
}
.lc-container tr td {
    vertical-align: middle;
}
#showConseiller h5 {
    color: #fff !important;
    font-weight: 600;
    font-size: 23px;
}
.col-md-12.cItem {
    padding: 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 15px;
}
span.lci-label {font-weight: 600;}

.component-liste-conseillers-oe .s-h-primo-1 {
    background: #273375 !important;
    width: 100%;
    padding: 0%;
}
.component-liste-conseillers-oe nav.navbar {
    margin-bottom: 0 !important;
}
.oe-liste-conseiller-table-container {
    padding: 20px;
    border-radius: 25px;
}
.action-btn.oe-view-btn{
    background: #273375 !important;
    border-color: #273375 !important;
}
.oe-liste-conseiller-table-container table tr td {
    text-align: center !important;
    font-size: 16px;
}