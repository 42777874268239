
footer{
    background: #010D16;
    padding: 65px 0 90px;
}
.logo-footer{
    width: 95px;
    margin-top: -2%;
}
.text-about-footer{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.copyright{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}
.h-footer{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-top: 20px;
}
.contact-us{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.link-footer{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
    transition: .2s ease-in-out;
    margin-bottom: 10px;
    display: block;
}
.link-footer:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    color: #0E5288;
}
.icon-contact-us{
    width: 38px;
    height: 38px;
    margin-right: 25px;
}
.h-contact{
    font-family: 'Advent Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}
.p-contact{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;    
    letter-spacing: 0.05em;
    color: #FFFFFF;
}