.fil-ariane-container {
    background: rgba(20, 135, 216, 0.1);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3%;
}
.fil-ariane-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: #273375;
}
.fil-ariane-content span,
.fil-ariane-content a {
    padding: 0 5px;
}
.ae-quizz-page-title h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #273375;
    padding: 40px 0;
    position: relative;
}
.ae-quizz-page-title h1:after {
    content: "";
    width: 10%;
    height: 4px;
    background: #E1000F !important;
    position: absolute;
    top: 75%;
    left: 0;
    right: 0;
    margin: auto;
}
.ae-quizz-container {
    padding: 0 10%;
    margin-bottom: 6%;
}
.counter-countdown-container {
    margin: 40px 0;
}
.ae-quizz-item-content-body {
    text-align: center;
}
.next-quizz-btn-container .carousel-control-next,
.next-quizz-btn-container .submit-btn {
    opacity: 1;
    width: 50% !important;
    background: #273375;
    border-radius: 70px;
    position: relative !important;
    margin-top: 10%;
    padding: 13px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
}
.next-quizz-btn-container {
    display: flex;
    justify-content: center;
}
.ae-quizz-item-content-body h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    /* text-transform: uppercase; */
    color: #273375;
}
.ae-quizz-form-group label {
    text-align: left !important;
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #273375 !important;
}
.ae-quizz-form-control {
    border: 1px solid #273375 !important;
    padding: 12px 10px;
    background-image: none !important;
    height: auto;
    border-radius: 10px;
}
.ae-quizz-select-input {
    height: 45px !important;
}
.ae-quizz-form-col {
    padding-bottom: 15px;
}
.ae-form-question {
    text-align: left !important;
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #273375 !important;
}
.radio-checkbox-inline-type-container {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;
}
.radio-checkbox-inline-type-container label {
    padding-right: 20px;
}
.ae-quizz-item-question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 33px;
    text-align: center;
    color: #273375;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.question-numero {
    background: #273375 !important;
    padding: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #ffffff;
    margin-right: 1%;
}
.ae-quizz-response-suggestion-item label {
    display: flex;
    text-align: left;
    align-items: center;
    padding-bottom: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #273375;
}
.ae-suggestion-radio-checkbox-form-control {
    width: 21px;
    height: 22px;
}
.special-ae-quizz-reponse-item-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
    padding: 10px 0;
}
.special-ae-input-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.moyennes-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.special-ae-input-item-content input:disabled {
    background: #ececec !important;
    cursor: pointer;
}
.special-ae-radio-box {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1%;
}
.special-ae-radio-box label {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    font-weight: 600;
}
.special-ae-input-form-control {
    border: 1px solid #273375;
    width: 70%;
    padding: 8px;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #273375;
    background: #FFFFFF !important;
}
.special-ae-input-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
}
.special-ae-input-item-content input {
    width: 95%;
}
.special-ae-input-item-content span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: #273375;
    text-align: left;
}
.ae-quizz-reponse-option-label, 
.ae-quizz-reponse-option-label-second-level {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: #273375;
    margin-left: -46%;
}
.ae-quizz-reponse-option-ul li::marker {
    font-size: 15px;
    color: #273375;
}
.ae-quizz-reponse-option-label-second-level-suggestion {
    margin-top: 10px;
}
.ae-quizz-result-light-image-container img {
    width: 100%;
    background: transparent !important;
}
.ae-quizz-resultat-item-row {
    align-items: center;
    justify-content: flex-start;
    text-align: left !important;
    margin-top: 10%;
    margin-bottom: 5%;
}
.ae-quizz-result-textual-container h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #273375;
}
/* .ae-quizz-result-textual-container p {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    font-size: 17px;
    line-height: 33px;
    color: #273375;
    padding: 35px 0;
} */
.quizz-final-btn {
    background: #273375 !important;
    border-radius: 70px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    text-transform: initial !important;
}
.counter-countdown-content {
    display: flex;
    justify-content: center;
}
.counter-countdown-content h2 {
    background: #C1004620 !important;
    padding: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.ae-required-field {
    color: #d3172d;
    font-weight: 900;
}
.ae-required-warning {
    border-radius: 5px;
    background: #ffddd370 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}
.ae-quizz-form-group i {
    text-align: left !important;
    display: inline-block;
    color: #e9324e;
}
.fcounter-container .question-numero {
    background: #273375 !important;
    padding: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #ffffff;
}
.fcounter-container {
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vdp {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    text-align: center;
    color: #273375;
    padding-left: 1%;
}
.consentement-nok-alert-danger {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    border-radius: 10px;
}
.consentement-nok-alert-container {
    margin: 30px 0;
}
.ae-quizz-title-infos-textual-content {
    margin: 4% 0;
    padding: 10px 20px;
}
.ae-quizz-title-infos-textual-content p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #273375;
    line-height: 30px;
}
.not-eligible-alert-infos-title {
    color: #C10046 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    margin: 3% 0;
}
.not-eligible-message-content p {
    color: #C10046 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    padding-bottom: 4%;
}
.return-back-not-eligible-btn {
    background: #273375 !important;
    border-radius: 50px;
    color: #ffffff !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    padding: 14px 30px;
    font-weight: 600;
}
.annee-encours-checkbox-container {
    display: flex;
    align-items: center;
    background: #C10046 !important;
    padding: 10px;
    border-radius: 20px;
}
.annee-encours-checkbox-container label {
    margin-bottom: 0;
    padding-bottom: 0;
}
.annee-encours-checkbox-container span {
    color: #ffffff !important;
    font-weight: 600;
}
.ae-quizz-result-textual-container h4 {
    background: #C10046 !important;
    padding: 10px 5px;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    margin: 10px 0;
    font-weight: 700;
}
.ae-quizz-result-textual-container h4 span {
    font-size: 13px;
}
.feedback-message-container p {
    font-size: 15px;
    padding: 5px 0;
    font-style: normal;
    line-height: 23px;
    font-family: 'Poppins';
    color: #273375;
}
.ae-quizz-result-textual-container {
    margin-top: 15%;
}
.reding-paragraph{
    color:#CD3535 !important;
}
.talk-with-helper-btn {
    background: #C10046 !important;
    color: #fff;
    font-weight: 600;
    padding: 15px;
    border-radius: 7px;
    font-size: 14px;
    border: 1px solid #C10046 !important;
}
.talk-with-helper-btn:hover {
    background: transparent !important;
    color: #C10046 !important;
}
.fire-color {
    text-transform: uppercase !important;
    font-size: 20px;
}
.fire-color.f-orange{
    color: #FFCD00 !important;
}
.fire-color.f-red{
    color: #E74C3C !important;
}
.fire-color.f-dark-green{
    color: #00AF51 !important;
}
.fire-color.f-ligth-green{
    color: #65B72F !important;
}
._3sXmFform-control.ae-quizz-form-control.ae-quizz-select-input,
.select__menu {
    text-align: left;
    border: none !important;
    padding: 7px;
}
._3sXmFform-control.ae-quizz-form-control.ae-quizz-select-input img {
    width: auto !important;
}
.form-group.ae-quizz-form-group ._3Ifyx {
    margin: 0;
    padding: 0;
    height: 45px !important;
}
.select__control.css-13cymwt-control {
    height: 46px !important;
    border: 1px solid #273375 !important;
    border-radius: 10px;
}
#react-select-2-placeholder,
.css-1jqq78o-placeholder {
    text-align: left !important;
}
.select__single-value.css-1dimb5e-singleValue {
    text-align: left;
}