#auto-eval-home-page-section {
    margin-top: -4%;
    padding-top: 7%;
    margin-bottom: 10%;
}

.home-icon-group {
    width: 30%;
    position: absolute;
    left: -16%;
    right: 0;
    margin: auto;
    top: -6%;
}
.auto-eval-home-page-content {
    position: relative;
}
.home-deco-vector {
    width: 100%;
}
.auto-eval-home-textual-container {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 42%;
    flex-direction: column;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.auto-eval-home-textual-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.auto-eval-next-item-btn {
    background: #273375 !important;
    border-radius: 70px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #FFFFFF;
}
.second-step .auto-eval-home-textual-content p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    text-transform: initial;
    padding: 0 10%;
}
.second-step .auto-eval-home-textual-container {
    top: 23%;
}
.auto-eval-warning-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 76px;
    line-height: 91px;
    text-align: center;
    color: #C10046;
}
.auto-eval-modal .modal-content {
    background: #FFFFFF;
    box-shadow: 10px 14px 34px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
}
.ae-separator {
    width: 100%;
    height: 1px;
    background: #ececec;
    margin: 10px 0;
}
.ae-warning-confirm-row-item {
    align-items: center;
}
.warning-confirm-textual-container p {
    text-align: justify;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 33px;
    color: #000000;
}
.ea-warning-submit-btn.nok-btn,
.ea-warning-submit-btn.nok-btn.nok-inprogress {
    background: #c31a1a !important;
}
.ea-warning-submit-btn.nok-btn:hover,
.ea-warning-submit-btn.nok-btn.nok-inprogress:hover {
    background: transparent !important;
    color: #c31a1a !important;
}
.ea-warning-submit-btn.ok-btn:hover,
.ea-warning-submit-btn.ok-btn.ok-inprogress:hover {
    background: transparent !important;
    color: #273375 !important;
}
.warning-confirm-input-group {
    display: flex;
    justify-content: space-around;
}
.warning-item-label {
    display: flex;
    align-items: center;
}
.warning-item-label.yesing {
    background: #50A55F;
    padding: 15px;
    border-radius: 10px;
}
input[type=radio].warning-confirm-input-check {
    width: 20px;
    height: 20px;
}
.warning-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}
.warning-item-label.noing {
    background: #E1000F;
    padding: 15px;
    border-radius: 10px;
}
.row.ea-warning-submit-btn-container {
    margin-top: 20px;
}
.ea-warning-submit-btn {
    background: #273375 !important;
    border-radius: 70px;
    width: 100%;
    text-transform: initial !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}
.auto-eval-modal .close {
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
    margin-top: 2%;
    opacity: 1;
}
.auto-eval-modal .close span {
    background: #ececec;
    padding: 5px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}
button.btn.btn-default.check-your-profile {
    background: #C10046 !important;
    border-radius: 70px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #FFFFFF;
}
.auto-eval-next-item-btn-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    position: relative;
    left: 39%;
}
.second-step .auto-eval-next-item-btn-container{
    left: 39%;
}