.component-copy-notification {
    position: fixed;
    top: 0;
    width: 100%;
    background: #273375 !important;
    color: #fff !important;
    z-index: 99999999;
    padding: 15px;
    text-align: center;
    transition: all 2s;
    transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
}