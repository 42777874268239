.no-empty-rdv-type {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #273375 !important;
    padding: 20px 0;
}
body {
    font-family: 'Poppins' !important;
}