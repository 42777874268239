div#root {
    height: inherit;
}

.component-empty-user-token-view {
    height: inherit;
    /* // background-color: #ff0000; */
}

.router-container {
    height: inherit;
}

.component-empty-user-token-view-header nav {
    background: #ffffff !important;
}
.row.homepage-content-container {
    height: inherit;
    background-image: url('/assets/landing-page/images/students.jpg');
    /* background-color: #3E5ABC; */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    height: 100%;
}
.row.homepage-content-container:before {
    background: linear-gradient(90deg, rgba(132, 89, 161, 1) 40%, rgba(70, 185, 232, 1) 94%) !important;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: .5;
}

.row.box-row {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    /* width: 100%; */
}
.container.card-container {
    position: relative;
}
.header-col {
    text-align: center;
}
.col-md-12.welcome-text-container {
    margin-top: 30px;
}
p.welcome {
    font-size: 29px;
    font-weight: 600;
    color: #da2929d9;
}
p.welcome-body {
    font-size: 25px;
    margin-top: 40px;
    color: #da2929d9;
}
.homagepage-btn-container, .fake-box {
    margin-top: 60px;
    text-align: right;
}
.h-action-btn {
    padding: 15px !important;
    width: 157px;
    color: #fff !important;
    font-weight: 900 !important;
}
.h-action-btn.h-register-btn {
    background: #31B89B !important;
}
div#navbarCollapse {
    margin: 33px 0;
}
a.btn.h-action-btn.h-home-btn {
    background: rgb(173,109,190);
    background: linear-gradient(259deg, rgba(173,109,190,1) 0%, rgba(84,78,202,1) 93%) !important;
    border-radius: 0;
    padding: 15px 30px;
    color: #fff !important;
    border: 1px solid rgba(84,78,202,1);
    padding: 15px !important;
    font-size: 17px !important;
    width: 100%;
}
a.btn.h-action-btn.h-home-btn:hover {
    background: #fff !important;
    color: rgb(173,109,190) !important;
    text-decoration: none;
    border: 1px solid rgb(173,109,190);
}
a.btn.h-action-btn.h-login-btn {
    background: rgb(173,109,190);
    background: linear-gradient(259deg, #31b89b 0%, #e5b336 93%);
    border-radius: 0;
    padding: 15px 30px;
    color: #fff !important;
    border: 1px solid #31b89b;
    padding: 15px !important;
    font-size: 17px !important;
    width: 100%;
}
a.btn.h-action-btn.h-login-btn:hover {
    background: #fff !important;
    color: #31b89b !important;
    text-decoration: none;
    border: 1px solid #31b89b;
}

/****************************************/
.component-mon-espace-empty-user-token-view nav.navbar {
    margin-bottom: 0 !important;
}
.component-mon-espace-empty-user-token-view .card {
    margin-top: 60%;
    border-radius: 50px;
}
.component-mon-espace-empty-user-token-view a.btn.h-action-btn.h-login-btn {
    border-radius: 50px !important;
    background: #273375 !important;
    border-color: #273375 !important;
}
.component-mon-espace-empty-user-token-view a.btn.h-action-btn.h-login-btn:hover {
    color: #273375 !important;
    background: transparent !important;
}
.component-mon-espace-empty-user-token-view a.btn.h-action-btn.h-home-btn {
    border-radius: 50px;
    background: #fff !important;
    color: #273375 !important;
}
.component-mon-espace-empty-user-token-view a.btn.h-action-btn.h-home-btn:hover {
    background: #273375 !important;
    color: #fff !important;
}
.component-mon-espace-empty-user-token-view .row.homepage-content-container:before {
    background: #273375 !important;
}
