.link-jm-btn {
    background: #273375 !important;
    border-color: #273375 !important;
    border-radius: 50px;
}
.delete-all-data-btn-container {
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .delete-all-data-btn {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #FFFFFF !important;
} */
.score-in-admin {
    color: #fff;
    padding: 10px;
    border-radius: 10px;
}
.admin-green{
    background: #2eb85c !important;
}
.admin-orange{
    background: #FF9800 !important;
}
.admin-red{
    background: #FF0000 !important;
}
table thead tr th {
    text-align: center !important;
}
.v2-admin-modal-header.modal-header {
    background: #273375 !important;
    padding: 15px;
}
.v2-admin-modal-title.modal-title {
    color: #ffffff;
    font-size: 17px;
    font-weight: 600;
}
.score-item-container {
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    color: #000;
}
.color-item-container div {
    display: block;
}

.session-type-choice-row {
    margin: 20px 0;
}
.session-type-choice-btn {
    margin: 15px 0;
    padding: 13px;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins';
    color: #ffffff;
    font-weight: 600;
    width: 100%;
}
.session-type-choice-btn.type-orientation {
    background: #00AF51 !important;
}
.session-type-choice-btn.type-aide {
    background: #65B72F !important;
}
.v2-admin-modal-footer-close-btn {
    background: red !important;
    font-size: 14px;
    color: #ffffff !important;
    font-weight: 700;
    border-radius: 10px;
    font-family: 'Poppins';
}
.color-item-container div {
    width: 100%;
    height: 20px;
    padding: 17px;
}
.v2-admin-modal-row {
    align-items: center;
}
.action-btn.view-private-planning-btn {
    background: #C10046 !important;
    /* margin-bottom: 5px; */
}
.show-modal-item-body {
    border: 1px solid #27337520 !important;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 10px;
}
.show-modal-item-label {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    color: #000;
}
.show-modal-item-value {
    font-family: 'Poppins';
    font-size: 16px;
    color: #000;
}
.private-plannings-link-container.has-plannings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.toast-custom-warning-link-container {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}
.redirect-to-manager-link {
    background: #273375 !important;
    padding: 15px;
    color: #ffffff;
    font-family: 'Poppins';
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #273375 !important;
}
.toast-custom-warning-textual-container p {
    font-size: 13px;
    text-align: justify;
    color: #C10046 !important;
}
.redirect-to-manager-link:hover {
    background: transparent !important;
    color: #273375 !important;
}
.open-generate-pl-btn {
    background: #C10046 !important;
    border-radius: 10px !important;
    color: #ffffff !important;
    font-weight: 900;
}
.open-generate-pl-btn:hover {
    background: #ffffff !important;
    color: #C10046 !important;
}
.delete-all-data-btn:hover {
    background: #f44336 !important;
    color: #ffffff !important;
}
.delete-all-data-btn:hover svg {
    color: #ffffff !important;
}
.delete-all-data-btn {
    background: #ffffff !important;
    border-radius: 10px;
}
.search-wrapper.searchWrapper {
    border: 1px solid #273375 !important;
    padding: 10px;
    border-radius: 10px;
}
#candidats-select-dropdown_input {
    width: 100%;
    font-size: 15px;
}
.option.highlightOption, li.option.highlightOption:hover {
    background: #273375 !important;
}
.multiSelectContainer li:hover {
    background: #273375 !important;
}
span.chip {
    background: #273375 !important;
}
.icon_cancel.closeIcon {
    background: #ff0000 !important;
    border-radius: 50%;
}
#multiple-generation-form .v2-admin-modal-footer.modal-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.v2-admin-modal-footer-submit-btn {
    background: #273375 !important;
    font-size: 14px;
    color: #ffffff !important;
    font-weight: 700;
    border-radius: 10px;
    font-family: 'Poppins';
}
.v2-admin-modal-footer-submit-btn:hover {
    background: transparent !important;
    color: #273375 !important;
}
.v2-admin-modal-footer-close-btn:hover {
    background: transparent !important;
    color: red !important;
}
.view-planning-link-by-admin {
    color: #C10046 !important;
}
.ae-stat-box-content-body {
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ae-stats-box-row {
    margin-bottom: 5%;
}
.ae-stat-box-item-label {
    height: 70px;
    font-weight: 600;
    font-size: 16px;
}
.ae-stat-box-item-value {
    font-size: 30px;
    color: #C10046 !important;
    font-weight: 700;
}
.ae-stat-box-content-body.nb-candidats {
    background: #C10046 !important;
}
.ae-stat-box-item-value {
    color: #ffffff !important;
}
.ae-stat-box-content-body .ae-stat-box-item-label {
    color: #ffffff !important;
}
.ae-stat-box-content-body.nb-candidat-ok {
    background: #99a2d5 !important;
}
.ae-stat-box-content-body.nb-candidat-nok {
    background: #FF0000 !important;
}
.v2-admin-modal-footer.modal-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.ep-period-input-container {
    margin-bottom: 20px;
}
.export-filtered-eval-col .export-to-xlsx-container.linkable.export-eval-to-excel span {
    padding: 20px !important;
    margin: 20px 0 !important;
}
.export-filtered-eval-col .v2-admin-modal-footer-close-btn {
    width: 100% !important;
    padding: 20px !important;
    margin: 20px 0 !important;
}
.export-filtered-eval-col .export-to-xlsx-container.linkable.export-eval-to-excel span:hover {
    color: #10793F !important;
}
.export-filtered-modal-btn {
    background: #ffffff !important;
    border-radius: 10px;
    border: 1px solid #10793F !important;
    color: #10793F !important;
}
.export-filtered-modal-btn:hover {
    background: #10793F !important;
    color: #ffffff !important;
}
.filtered-export-title {
    color: #273375 !important;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin: 20px 0;
}
#period-export-form .ae-quizz-alert-danger {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}