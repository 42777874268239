.component-tutoriel-video-manager nav.navbar.navbar-light.bg-white.box-shadow {
    margin-bottom: 0 !important;
}
.component-tutoriel-video-manager .bordure-bas {
    border-bottom: 0 solid transparent !important;
}
.component-tutoriel-video-manager section#cd_container {
    background: #273375 !important;
}
.youtube_video_preview {
    width: 100% !important;
    text-align: center;
}
.youtube_preview {
    width: 100% !important;
    height: 135px !important;
}
.orm-table-container table td {
    vertical-align: middle !important;
    text-align: center !important;
}