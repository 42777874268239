.profil-etudiant-section-row {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.profil-etudiant-section-row .ae-quizz-item-content-body h2 {
    font-size: 20px;
    margin-bottom: 25px;
}
.infos-etudiant-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #C10046 !important;
}
.profil-etudiant-infos-col {
    margin-bottom: 20px;
}
.infos-etudiant-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #000000;
    display: flex;
    align-items: center;
}
.request-error-section {
    margin-bottom: 50px;
}
.moyenne-etudiant-row {
    padding: 8px 0;
    border: 1px solid #ececec;
}
.profil-view-by-cf-conseiller .print-btn-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 20px;
}
.export-profile-pdf-btn {
    background: #C10046 !important;
    border-radius: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff !important;
}
.score-item-value {
    background: #273375 !important;
    margin: 0 2%;
    padding: 7px;
    display: inline-block;
    text-align: center;
    color: #ffffff !important;
    border-radius: 10px;
    font-size: 11px;
}
.total-score {
    background: #273375;
    border-radius: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    color: #ffffff !important;
    margin: 0.375rem;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    border: 0;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    padding: 0.84rem 2.14rem;
    font-size: 15px;
}

