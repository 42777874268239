.component-list-generated-links .s-h-primo-1 {
    background: #273375 !important;
    width: 100%;
    padding: 0%;
}
.component-list-generated-links nav.navbar {
    margin-bottom: 0 !important;
}
.oe-liste-liens-table-container {
    padding: 20px;
    border-radius: 25px;
}
input.filter.text-filter.form-control {
    background-image: none;
    border-radius: 50px !important;
}
.oe-liste-liens-table-container table tr td {
    font-size: 14px;
}
.link-item-container {
    padding: 20px 0;
}
.separator-line {
    width: 100%;
    height: 2px;
    background: #ececec;
}
.link-item-title {
    color: #273375 !important;
    font-size: 20px;
    font-weight: 600;
}
.link-item-content {
    color: #273375 !important;
    line-height: 27px;
}