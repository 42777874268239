.component-admin-and-conseiller-dashboard nav.navbar.navbar-light.bg-white.box-shadow {
    margin-bottom: 0 !important;
}
.component-admin-and-conseiller-dashboard .bordure-bas {
    border-bottom: 0 solid transparent !important;
}
.component-admin-and-conseiller-dashboard section#cd_container {
    background: #273375 !important;
}
.active_chat_container {
    margin-bottom: 50px;
    text-align: center;
}
.disable_chat_link {
    background: rgb(230, 65, 75);
    border: 1px solid rgb(230, 65, 75);
    padding: 15px;
    border-radius: 50px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}
.chat_label_status {
    color: #fff;
    font-weight: 600;
}
.disable_chat_link:hover {
    background: #fff !important;
    color: rgb(230, 65, 75) !important;
}
.active_chat_link {
    background: rgb(117, 196, 107);
    padding: 15px;
    border-radius: 50px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    border: 1px solid rgb(117, 196, 107) !important;
}
.active_chat_link:hover {
    background: #fff !important;
    color: rgb(117, 196, 107) !important;
}
.conseiller-alert-danger,
.conseiller-alert-success {
  padding: 20px !important; 
  font-size: 22px !important;
  font-weight: 600 !important;
}
.glink-modal-btn-container {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
}
.glink-modal-btn {
    width: 70%;
    padding: 15px;
    border-radius: 50px;
    background: #273375 !important;
    border: 3px solid #fff !important;
    color: #fff !important;
}
.glink-icon {
    width: 50px;
    height: 50px;
}
.glink-modal-btn:hover {
    background: #fff !important;
    color: #273375 !important;
    border-color: #273375 !important;
}
#glink-form .row.empty-alert-row {
    margin: 0;
    width: 100%;
}
section.conditional-data-container {
    width: 100%;
}
[class^=showable]{
    display: none;
}
.custum-fa{
    font-size: 22px !important;
}
.row.form-cb-btn-row {
    width: 100%;
}
.form-cb-btn-col {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.eo-btn-danger {
    color: #fff !important;
    font-size: 20px;
    padding: 18px;
    font-weight: 600;
    border-radius: 50px;
    width: 100%;
    border-color: #f44336;
}
.eo-btn-danger:hover {
    background: #fff !important;
    color: #f44336 !important;
}
.chat_label_status{
    text-align: center !important;
}
#generate-link-section {
    width: 100%;
}
.section-title {
    text-align: center;
    color: #fff !important;
    font-weight: 600;
}


.conseiller-inbox-container {
    padding-bottom: 100px;
}
.need-help-chart-content {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}
.need-help-chart-container {
    padding-bottom: 50px;
}
.nhf-form-control {
    background-image: none !important;
    height: 40px !important;
    border-radius: 35px;
    border-color: #273375;
}
.nhf-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    height: inherit !important;
}
.nhf-row {
    padding-top: 30px;
    padding-bottom: 30px;
}
.need-help-btn-filter {
    width: 100%;
    background: #273375 !important;
    border-radius: 25px;
    border-color: #273375;
    color: #fff !important;
    margin: 0;
    height: 40px !important;
    font-weight: 700;
}
.need-help-btn-filter:hover {
    background: #fff !important;
    color: #273375 !important;
}
#filter-need-help-count-form {
    border: 1px solid #ececec;
    border-radius: 20px;
    margin-bottom: 40px;
}
.chart-title {
    color: #273375 !important;
    font-weight: 600;
    margin: 20px 0;
    border-bottom: 1px solid #273375;
    padding: 10px 0;
}
.oe-alert-danger.filter-error-alert {
    padding: 8px;
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
    border-radius: 25px !important;
}
.oe-server-error-message-container .oe-alert-danger {
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
}